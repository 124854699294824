import type { HeaderItem } from "@cm-websites/common";

export const HEADER_ITEMS: HeaderItem[] = [
  { label: "Dîner-enquête", route: "/" },
  { label: "Acheter", href: "https://localisation-brg.com/culinariomortale" },
  { label: "Dîner-enquête en ligne", route: "/diner-enquete-en-ligne" },
  {
    label: " Aide",
    children: [
      {
        label: "Qu’est-ce que c’est ?",
        route: "/quest-ce-quun-diner-enquete",
      },
      { label: "Règles du jeu et déroulement", route: "/regles-du-jeu" },
      { label: "Télécharger les descriptions des personnages", route: "/preparation" },
      { label: "Télécharger des documents manquants", route: "/documents-manquants" },
      { label: "Foire aux questions", route: "/foire-aux-questions" },
      { label: "Contact", route: "/contact" },
    ],
  },
];
