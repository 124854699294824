import type { FooterMenusProps } from "@cm-websites/common";

export const FOOTER_MENUS: FooterMenusProps["menus"] = [
  {
    title: "Souvent recherché",
    items: [
      {
        label: "Dîner-enquête pour 5 joueurs",
        path: "/diner-enquete-pour-5-joueurs",
      },
      {
        label: "Dîner-enquête pour 6 joueurs",
        path: "/diner-enquete-pour-6-joueurs",
      },
      {
        label: "Dîner-enquête pour 7 joueurs",
        path: "/diner-enquete-pour-7-joueurs",
      },
      {
        label: "Dîner-enquête pour 8 joueurs",
        path: "/diner-enquete-pour-8-joueurs",
      },
      {
        label: "Dîner-enquête en ligne",
        path: "/diner-enquete-en-ligne",
      },
      {
        label: "Dîner-enquête en allemand",
        path: "https://www.culinario-mortale.de",
        external: true,
      },
      {
        label: "Dîner-enquête en anglais",
        path: "https://www.culinario-mortale.co.uk",
        external: true,
      },
      {
        label: "Toutes les langues",
        path: "/langues",
      },
    ],
  },
  {
    title: "Nos jeux",
    items: [
      {
        label: "Qu’est-ce que c’est ?",
        path: "/quest-ce-quun-diner-enquete",
      },
      {
        label: "Règles du jeu et déroulement",
        path: "/regles-du-jeu",
      },
      {
        label: "Foire aux questions",
        path: "/foire-aux-questions",
      },
      { label: "Télécharger les descriptions des personnages", path: "/preparation" },
      { label: "Télécharger des documents manquants", path: "/documents-manquants" },
    ],
  },
  {
    title: "Informations",
    items: [
      { label: "Mentions légales", path: "/mentions-legales" },
      { label: "Politique de confidentialité", path: "/politique-de-confidentialite" },
      { label: "Contact", path: "/contact" },
    ],
  },
];
