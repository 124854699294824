import { FOOTER_MENUS } from "./footer-menus";
import { FooterContainer, FooterMenus } from "@cm-websites/common";
import React from "react";
import FacebookIcon from "../../public/icons/facebook.svg";
import InstagramIcon from "../../public/icons/instagram.svg";
import PinterestIcon from "../../public/icons/pinterest.svg";

export const Footer: React.FC = () => (
  <FooterContainer>
    <FooterMenus
      headingComponent="h4"
      menus={FOOTER_MENUS}
      socialMedia={{
        title: "Social Media",
        items: [
          {
            icon: InstagramIcon,
            label: "Suivez-nous sur Instagram",
            path: "https://www.instagram.com/culinario.mortale/",
          },
          {
            icon: PinterestIcon,
            label: "Suivez-nous sur Pinterest",
            path: "https://www.pinterest.de/culinariomortale/",
          },
          {
            icon: FacebookIcon,
            label: "Suivez-nous sur Facebook",
            path: "https://www.facebook.com/culinario.mortale/",
          },
        ],
      }}
    />
  </FooterContainer>
);
